import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import Particles from "../components/particles"
import Layout from "../components/layout"
import Profile from '../components/profile';
import SEO from "../components/seo"
import blogStyles from './index.module.scss'

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              date
              featuredImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
              readingTime {
                text
              }
            }
            excerpt(pruneLength: 300)
          }
        }
      }
      site {
        siteMetadata {
          keywords
        }
      }
    }
  `);

  return (
    <>
    <Particles />
    <Layout>
      <SEO title="Home" keywords={data.site.siteMetadata.keywords} />
      <div className="home">
        <Profile />
        <br />
        <div className={blogStyles.articlesContainer}>
          <h1>Articles</h1>
          <div className={blogStyles.posts}>
            {data.allMarkdownRemark.edges.map((edge, index) => {
              return (
                index <= 1 ?
                <div key={index} className={blogStyles.topPost}>
                  <div className={blogStyles.articleThumbnail}>
                    <Img fluid={edge.node.frontmatter.featuredImage.childImageSharp.fluid} />
                    <p className={blogStyles.readTime}><span role="img" aria-label="read-time">&#9203;{edge.node.fields.readingTime.text}</span></p>
                  </div>
                  <div className={blogStyles.summary}>
                    <h4 className={blogStyles.titleText}>{edge.node.frontmatter.title}</h4>
                    <div className={blogStyles.excerpt}>
                      {
                        edge.node.excerpt.replace(/source.+http.+?\s/g, '').replace(/\n/g, ' ').split(' ').slice(0, 20).join(' ') + ' . . .'
                      }
                    </div>
                    <div className={blogStyles.postButton}>
                      <Link className={blogStyles.readButton} to={`/${edge.node.fields.slug}`}>
                        Read...
                      </Link>
                    </div>
                  </div>
                </div>
                :
                <div key={index} className={blogStyles.post}>
                  <div className={blogStyles.articleThumbnail}>
                    <Img fluid={edge.node.frontmatter.featuredImage.childImageSharp.fluid} />
                    <p className={blogStyles.readTime}><span role="img" aria-label="read-time">&#9203;{edge.node.fields.readingTime.text}</span></p>
                  </div>
                  <div className={blogStyles.summary}>
                    <h4 className={blogStyles.titleText}>{edge.node.frontmatter.title}</h4>
                    <div className={blogStyles.excerpt}>
                      {
                        edge.node.excerpt.replace(/source.+http.+?\s/g, '').replace(/\n/g, ' ').split(' ').slice(0, 20).join(' ')
                      }
                    </div>
                    <div className={blogStyles.postButton}>
                      <Link className={blogStyles.readButton} to={`/${edge.node.fields.slug}`}>
                        Read...
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
    </>
  );
}

export default BlogPage;
