import React from 'react';
import FollowAt from './followAt';
import ProfileImage from './profileImage';

const profile = () => {
  return (
    <div className="profile-container">
      <div>
        <ProfileImage />
        <div className="title">Software / DevOps Engineer</div>
        <div className="view-profile"></div>
        <FollowAt />
        <div className="intro">
          I am a DevOps Engineer and Fullstack JavaScript Developer (QA, Frontend, Backend).
          I have expertise in pipeline as code, infrastructure as code, micro-services architecture, service discovery, configuration and change management,
          bash scripting, containerization and container orchestration, monitoring and logging, Kubernetes, Docker, terraform, Ansible, JavaScript, TypeScript,
          Postgresql RDBMS, Express, React, NodeJS, etc.
          I deploy and manage infrastructures on AWS and GCP.
        </div>
      </div>
    </div>
  )
}

export default profile;
