import React from 'react'
import Particles from 'react-particles-js';

const particles = () => {
  return (
    <Particles
      width={'100vw'}
      height={'100vh'}
      className="particles"
      params={{
        particles: {
          shape: {
            type: 'triangle',
            image: {
              width: 100,
              height: 100
            }
          },
          number: {
            value: 20,
            density: {
              enable: true
            }
          }
        },
        interactivity: {
          events: {
            onhover: {
              enable: true,
              mode: 'repulse'
            },
            onclick: {
              enable: true,
              mode: 'push'
            }
          },
          detect_on: 'canvas',
        }
      }}
    />
  )
}

export default particles;
